<template>
  <div class="tei-editor-component">
    <div v-b-visible="refreshEditor" class="editor-wrapper">
      <b-button-toolbar class="mb-3">
        <SpecialCharacterSelect @change="insertCharacter" />

        <ThemeSelect
          :active="currentTheme"
          class="mx-1"
          @change="changeTheme"
        />
      </b-button-toolbar>
      <!-- The TEI/XML editor -->
      <div class="editor-resize-wrapper">
        <div ref="editorpane" class="editor mb-3" @keyup="editorChange" />
      </div>
    </div>
  </div>
</template>

<script>
import CodeMirror from "codemirror";

import "codemirror/mode/xml/xml.js";
import "codemirror/addon/display/placeholder.js";
import "codemirror/addon/edit/closetag.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchtags.js";
import "codemirror/addon/fold/xml-fold.js";
import "codemirror/addon/hint/xml-hint.js";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/selection/active-line.js";

// Styling
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";

// Themes for codemirror
import "codemirror/theme/blackboard.css";
import "codemirror/theme/eclipse.css";
import "codemirror/theme/pastel-on-dark.css";
import "codemirror/theme/base16-light.css";

import beautify from "js-beautify";

export default {
  name: "PlainText",
  components: {
    SpecialCharacterSelect: () => import("./SpecialCharacterSelect"),
    ThemeSelect: () => import("./ThemeSelect")
  },
  model: {
    prop: "text",
    event: "change"
  },
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editor: Object,
      editorContent: this.text,
      formattedContent: null
    };
  },
  computed: {
    currentTheme() {
      return localStorage.getItem("uu.codemirror.theme.name") || "blackboard";
    }
  },
  watch: {
    text: function(newValue) {
      if (this.editorContent != newValue) {
        // got actual new value not just feedback on change
        this.editorContent = newValue;
        this.reformatEditorContent();
      }
    },
    editorContent() {
      this.$emit("change", this.editorContent);
    }
  },
  mounted: function() {
    this.reformatEditorContent();
    this.editor = CodeMirror(this.$refs.editorpane, {
      value: this.formattedContent,
      mode: null,
      lineWrapping: true,
      theme: this.currentTheme,
      placeholder: this.$t("components.tei.plainPlaceHolder"),
      lineNumbers: true,
      htmlMode: false,
      styleActiveLine: true,
      viewportMargin: Infinity
    });
  },
  methods: {
    refreshEditor() {
      this.editor.refresh();
    },
    editorChange() {
      this.editorContent = this.editor.getValue();
    },
    reformatEditorContent() {
      this.formattedContent = this.editorContent;
      //Update the editor with the reformatted value, if editor is ready to accept (not at time of init)
      if (this.editor && this.editor.getDoc) {
        this.editor.getDoc().setValue(this.formattedContent);
      }
    },
    insertCharacter(character) {
      this.editor.replaceSelection(character);
      this.editorChange();
      this.editor.focus();
    },
    changeTheme(theme) {
      // Save theme selection to local storage, will be read in this.currentTheme()
      localStorage.setItem("uu.codemirror.theme.name", theme.theme);
      // Update the editor to use the selected theme
      this.editor.setOption("theme", theme.theme);
    }
  }
};
</script>
